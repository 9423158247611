const GlobalLocalName = 'zst_saas_school_manage_txcode';

const GlobalLocalName2 = 'zst_saas_school_manage_userinfo';

const Global_project_type_obj = {
    '1': '选缴',
    '2': '必缴',
}

const Global_project_status_obj = {
    '0': '冻结',
    '1': '发布',
}

const Global_pay_type_obj = {
    '1': '微信',
    '2': '现金录入'
}

const Global_pay_status_obj = {
    '1': '支付成功',
    '2': '已退款'
}

const Global_refund_type_obj = {
    '1': '微信',
    '2': '现金'
}

const Global_refund_status_obj = {
    '0': '失败',
    '1': '成功'
}

const Global_export_status_obj = {
    '0': '失败',
    '1': '成功',
    '2': '进行中',
}

const Global_feetypes_obj = {
    '0': '普通费用',
    '1': '报名费',
}

const Global_admission_status_obj = {
    '0': '待审核',
    '1': '已录取',
    '2': '拒绝录取',
    '3': '已退学',
    // '4':'预录取',
    '5': '预录取待缴费',
    '6': '退款待审核',
    '7': '退款已通过',
    '8': '待补费',
}

const GlobalShenfenzhengReg = /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/

const GlobalMobileReg = /^1[3456789][0-9]{9}$/

const GlobalTree = [
    {
        id: 0,
        name: '学校',
        level: 1,
    },
    {
        id: -1,
        name: '未分配'
    }
]
const GlobalVersion = '1.0.13.4'

export default {
    GlobalLocalName,
    GlobalLocalName2,
    Global_project_type_obj,
    Global_project_status_obj,
    Global_pay_type_obj,
    Global_pay_status_obj,
    Global_refund_type_obj,
    Global_refund_status_obj,
    Global_export_status_obj,
    GlobalShenfenzhengReg,
    GlobalMobileReg,
    GlobalTree,
    Global_feetypes_obj,
    Global_admission_status_obj,
    GlobalVersion
}

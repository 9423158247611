import {createRouter, createWebHashHistory} from 'vue-router'
import Home from '../views/Home.vue'
import {ElMessageBox} from 'element-plus';
import Global from '../Global.js'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            title: '首页',
            keepAlive: false,
        },
        children: [
            {
                path: '/frontPage',
                name: 'frontPage',
                component: () => import('../views/frontPage/frontPage.vue'),
                meta: {
                    title: '首页',
                    keepAlive: false,
                }
            },
            {
                path: '/userTeacher',
                name: 'userTeacher',
                component: () => import('../views/user/userTeacher.vue'),
                meta: {
                    title: '教职工管理',
                    keepAlive: false,
                }
            },
            {
                path: '/userStudent',
                name: 'userStudent',
                component: () => import('../views/user/userStudent.vue'),
                meta: {
                    title: '学生管理',
                    keepAlive: false,
                }
            },
            {
                path: '/recruitPlan',
                name: 'recruitPlan',
                component: () => import('../views/recruit/recruitPlan.vue'),
                meta: {
                    title: '招生计划',
                    keepAlive: false,
                }
            },
            {
                path: '/recruitTeacher',
                name: 'recruitTeacher',
                component: () => import('../views/recruit/recruitTeacher.vue'),
                meta: {
                    title: '招生老师',
                    keepAlive: false,
                }
            },
            {
                path: '/recruitRanking',
                name: 'recruitRanking',
                component: () => import('../views/recruit/recruitRanking.vue'),
                meta: {
                    title: '招生排名',
                    keepAlive: false,
                }
            },
            {
                path: '/signupProcess',
                name: 'signupProcess',
                component: () => import('../views/signup/signupProcess.vue'),
                meta: {
                    title: '报名流程',
                    keepAlive: false,
                }
            },
            {
                path: '/signupStudent',
                name: 'signupStudent',
                component: () => import('../views/signup/signupStudent.vue'),
                meta: {
                    title: '学生档案',
                    keepAlive: false,
                }
            },
            {
                path: '/signupCustomForm',
                name: 'signupCustomForm',
                component: () => import('../views/signup/signupCustomForm.vue'),
                meta: {
                    title: '自定义表单',
                    keepAlive: false,
                }
            },
            {
                path: '/payReceivable',
                name: 'payReceivable',
                component: () => import('../views/pay/payReceivable.vue'),
                meta: {
                    title: '缴费项目',
                    keepAlive: false,
                }
            },
            {
                path: '/paySort',
                name: 'paySort',
                component: () => import('../views/pay/paySort.vue'),
                meta: {
                    title: '类别管理',
                    keepAlive: false,
                }
            },
            {
                path: '/payPaid',
                name: 'payPaid',
                component: () => import('../views/pay/payPaid.vue'),
                meta: {
                    title: '已缴查询',
                    keepAlive: false,
                }
            },
            {
                path: '/payRefund',
                name: 'payRefund',
                component: () => import('../views/pay/payRefund.vue'),
                meta: {
                    title: '退款查询',
                    keepAlive: false,
                }
            },
            {
                path: '/payPending',
                name: 'payPending',
                component: () => import('../views/pay/payPending.vue'),
                meta: {
                    title: '待缴查询',
                    keepAlive: false,
                }
            },
            {
                path: '/paySummary',
                name: 'paySummary',
                component: () => import('../views/pay/paySummary.vue'),
                meta: {
                    title: '数据汇总',
                    keepAlive: false,
                }
            },
            {
                path: '/payProjectReport',
                name: 'payProjectReport',
                component: () => import('../views/pay/payProjectReport.vue'),
                meta: {
                    title: '项目报表',
                    keepAlive: false,
                }
            },
            {
                path: '/payDepartmentReport',
                name: 'payDepartmentReport',
                component: () => import('../views/pay/payDepartmentReport.vue'),
                meta: {
                    title: '部门报表',
                    keepAlive: false,
                }
            },
            {
                path: '/systemSchool',
                name: 'systemSchool',
                component: () => import('../views/system/systemSchool.vue'),
                meta: {
                    title: '基础信息',
                    keepAlive: false,
                }
            },
            {
                path: '/systemDown',
                name: 'systemDown',
                component: () => import('../views/system/systemDown.vue'),
                meta: {
                    title: '预约下载',
                    keepAlive: false,
                }
            },
            {
                path: '/systemStudent',
                name: 'systemStudent',
                component: () => import('../views/system/systemStudent.vue'),
                meta: {
                    title: '学生信息',
                    keepAlive: false,
                }
            },
            {
                path: '/systemOperate',
                name: 'systemOperate',
                component: () => import('../views/system/systemOperate.vue'),
                meta: {
                    title: '操作记录',
                    keepAlive: false,
                }
            },
            {
                path: '/systemPermission',
                name: 'systemPermission',
                component: () => import('../views/system/systemPermission.vue'),
                meta: {
                    title: '权限管理',
                    keepAlive: false,
                }
            },
            {
                path: '/systemAddRouter',
                name: 'systemAddRouter',
                component: () => import('../views/system/systemAddRouter.vue'),
                meta: {
                    title: '基础配置',
                    keepAlive: false,
                }
            },
            {
                path: '/systemImport',
                name: 'systemImport',
                component: () => import('../views/system/systemImport.vue'),
                meta: {
                    title: '导入记录',
                    keepAlive: false,
                }
            },
            {
                path: '/paySignUpPaid',
                name: 'paySignUpPaid',
                component: () => import('../views/pay/paySignUpPaid.vue'),
                meta: {
                    title: '报名已缴查询',
                    keepAlive: false,
                }
            },
            {
                path: '/paySignUpRefund',
                name: 'paySignUpRefund',
                component: () => import('../views/pay/paySignUpRefund.vue'),
                meta: {
                    title: '报名退款查询',
                    keepAlive: false,
                }
            },
            {
                path: '/systemCustomMenu',
                name: 'systemCustomMenu',
                component: () => import('../views/system/systemCustomMenu.vue'),
                meta: {
                    title: '微信自定义菜单',
                    keepAlive: false,
                }
            },
            {
                path: '/paySignUpApply',
                name: 'paySignUpApply',
                component: () => import('../views/pay/paySignUpApply.vue'),
                meta: {
                    title: '报名退款申请',
                    keepAlive: false,
                }
            },
            {
                path: '/paySignUpRefundRun',
                name: 'paySignUpRefundRun',
                component: () => import('../views/pay/paySignUpRefundRun.vue'),
                meta: {
                    title: '报名退款执行',
                    keepAlive: false,
                }
            },
            {
                path: '/signupStatistics',
                name: 'signupStatistics',
                component: () => import('../views/signup/signupStatistics.vue'),
                meta: {
                    title: '报名统计',
                    keepAlive: false,
                }
            },
            {
                path: '/recruitStatistics',
                name: 'recruitStatistics',
                component: () => import('../views/recruit/recruitStatistics.vue'),
                meta: {
                    title: '招生计划统计',
                    keepAlive: false,
                }
            },

        ]
    },
    {
        path: '/login',
        name: 'login',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/login.vue'),
        meta: {
            title: '登陆',
            keepAlive: false,
        }
    },
    {
        path: '/auth',
        name: 'auth',
        component: () => import('../views/auth.vue'),
        meta: {
            title: '鉴权',
            keepAlive: false,
        }
    },
    {
        path: '/proof',
        name: 'proof',
        component: () => import('../views/proof.vue'),
        meta: {
            title: '下载收据',
            keepAlive: false,
        }
    },
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

//引入路由控制
const whiteList = ['auth', 'login']; //不重定向白名单

router.beforeEach((to, from, next) => {
    //先判断是否存储有信息
    if (localStorage.getItem(Global.GlobalLocalName2) == null) {
        if (whiteList.indexOf(to.name) === -1) {
            ElMessageBox.alert('登陆信息缺失', '提示', {
                confirmButtonText: '重新登陆',
                callback: () => {
                    router.push('/login')
                },
            })
        }
        else {
            next()
        }
    }
    else {
        next()
    }
})

export default router

<template>
  <ElConfigProvider :locale="locale">
    <router-view></router-view>
  </ElConfigProvider>
</template>
<script >
    import { ElConfigProvider } from 'element-plus';
    import { ref } from 'vue';
    import zhLocale from 'element-plus/lib/locale/lang/zh-cn';

    export default {
        components: {
            [ElConfigProvider.name]: ElConfigProvider, //添加组件
        },
        setup() {
            const locale = ref(zhLocale);
            return {
                locale,
            };
        },
    };
</script>
